export default function PhoneIcon({ fillColor }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2022_3606)">
        <path
          d="M33.6 6H14.4C9.76081 6 6 9.76081 6 14.4V33.6C6 38.2392 9.76081 42 14.4 42H33.6C38.2392 42 42 38.2392 42 33.6V14.4C42 9.76081 38.2392 6 33.6 6Z"
          fill="#F39D38"
        />
        <mask
          id="mask0_2022_3606"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="6"
          width="36"
          height="36"
        >
          <path
            d="M33.6 6H14.4C9.76081 6 6 9.76081 6 14.4V33.6C6 38.2392 9.76081 42 14.4 42H33.6C38.2392 42 42 38.2392 42 33.6V14.4C42 9.76081 38.2392 6 33.6 6Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2022_3606)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4453 28.3516L22.203 25.5938L24.3243 27.7152C25.6162 29.007 26.6578 29.4122 28.567 27.9273C28.9912 27.503 29.7337 26.9727 30.5822 27.397L34.6127 30.1547C35.7795 31.1093 35.8747 31.6305 35.5673 32.3821C33.6594 37.0456 27.6124 36.3065 19.4453 28.3516ZM19.4453 28.3516C11.4904 20.1845 10.7513 14.1374 15.4148 12.2295C16.1664 11.9221 16.6876 12.0174 17.6422 13.1841L20.3999 17.2146C20.8242 18.0632 20.2938 18.8056 19.8696 19.2299C18.3846 21.1391 18.7898 22.1806 20.0817 23.4725L22.203 25.5938L19.4453 28.3516Z"
            fill={fillColor}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2022_3606">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
