import { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import commonStyles from "./Portfolio.module.css";
import styles from "./News.module.css";
import { newsList } from "../Components/NewsComponents/newsList";
import NewsItem from "../Components/NewsComponents/NewsItem";

export default function News() {
  let newsListLeft = [];
  let newsListRight = [];
  console.log(newsListLeft);
  useMemo(() => {
    newsList.forEach((item) => {
      item.id % 2 === 0 ? newsListRight.push(item) : newsListLeft.push(item);
    });
  }, [newsListLeft, newsListRight]);

  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const changeScreenWidth = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
    };
    changeScreenWidth();
    window.addEventListener("resize", changeScreenWidth);
    return () => window.removeEventListener("resize", changeScreenWidth);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Stay updated with the latest trends and insights in event production from Los Angeles to the rest of the world with the 1987 Masters blog. Explore expert tips and industry news to enhance your event planning globally."
        />
        <meta
          name="keywords"
          content="Global event production blog Los Angeles"
        />
        <title>News</title>
      </Helmet>
      <div className={commonStyles.headerPortfolio}>
        <h2>News</h2>
      </div>

      <div className={styles.newsWrapper}>
        {screenWidth < 1280 ? (
          <NewsItem newsList={newsList} />
        ) : (
          <>
            <NewsItem newsList={newsListLeft} />
            <NewsItem newsList={newsListRight} />
          </>
        )}
      </div>
    </>
  );
}
