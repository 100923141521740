export default function FacebookLogo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.804 42V28.068H35.47L36.168 22.652H30.804V19.196C30.804 17.628 31.24 16.558 33.488 16.558H36.356V11.714C34.9676 11.5667 33.5722 11.4952 32.176 11.5C28.042 11.5 25.212 14.024 25.212 18.66V22.652H20.536V28.068H25.212V42H8C7.46957 42 6.96086 41.7893 6.58579 41.4142C6.21071 41.0391 6 40.5304 6 40V8C6 7.46957 6.21071 6.96086 6.58579 6.58579C6.96086 6.21071 7.46957 6 8 6H40C40.5304 6 41.0391 6.21071 41.4142 6.58579C41.7893 6.96086 42 7.46957 42 8V40C42 40.5304 41.7893 41.0391 41.4142 41.4142C41.0391 41.7893 40.5304 42 40 42H30.804Z"
        fill="#F39D38"
      />
    </svg>
  );
}
