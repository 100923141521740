export default function InstagramLogo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 17.5C22.2761 17.5 20.6228 18.1848 19.4038 19.4038C18.1848 20.6228 17.5 22.2761 17.5 24C17.5 25.7239 18.1848 27.3772 19.4038 28.5962C20.6228 29.8152 22.2761 30.5 24 30.5C25.7239 30.5 27.3772 29.8152 28.5962 28.5962C29.8152 27.3772 30.5 25.7239 30.5 24C30.5 22.2761 29.8152 20.6228 28.5962 19.4038C27.3772 18.1848 25.7239 17.5 24 17.5Z"
        fill="#F39D38"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5396 6.16389C20.4917 5.39329 27.5076 5.39329 34.4596 6.16389C38.2576 6.58789 41.3196 9.57789 41.7656 13.3899C42.5901 20.4392 42.5901 27.5606 41.7656 34.6099C41.3196 38.4219 38.2576 41.4119 34.4616 41.8379C27.5089 42.6087 20.4923 42.6087 13.5396 41.8379C9.74164 41.4119 6.67964 38.4219 6.23364 34.6119C5.4091 27.5619 5.4091 20.4399 6.23364 13.3899C6.67964 9.57789 9.74164 6.58789 13.5396 6.16389ZM33.9996 11.9999C33.4692 11.9999 32.9605 12.2106 32.5854 12.5857C32.2104 12.9607 31.9996 13.4695 31.9996 13.9999C31.9996 14.5303 32.2104 15.039 32.5854 15.4141C32.9605 15.7892 33.4692 15.9999 33.9996 15.9999C34.5301 15.9999 35.0388 15.7892 35.4139 15.4141C35.7889 15.039 35.9996 14.5303 35.9996 13.9999C35.9996 13.4695 35.7889 12.9607 35.4139 12.5857C35.0388 12.2106 34.5301 11.9999 33.9996 11.9999ZM14.4996 23.9999C14.4996 21.4803 15.5005 19.064 17.2821 17.2824C19.0637 15.5008 21.4801 14.4999 23.9996 14.4999C26.5192 14.4999 28.9356 15.5008 30.7172 17.2824C32.4988 19.064 33.4996 21.4803 33.4996 23.9999C33.4996 26.5194 32.4988 28.9358 30.7172 30.7174C28.9356 32.499 26.5192 33.4999 23.9996 33.4999C21.4801 33.4999 19.0637 32.499 17.2821 30.7174C15.5005 28.9358 14.4996 26.5194 14.4996 23.9999Z"
        fill="#F39D38"
      />
    </svg>
  );
}
