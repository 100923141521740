import styles from "./About.module.css";

export default function Careers() {
  return (
    <div className={`${styles.aboutContainer}`}>
      <div style={{ marginTop: "100px" }}>
        <h1>Careers</h1>
      </div>
    </div>
  );
}
