import { useEffect } from "react";
import styles from "./ClientsAreSaying.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrewArrow } from "../../UI/Arrows";
import { NextArrow } from "../../UI/Arrows";

export default function ClientsAreSaying(
  {
    // setLatestPosition
  }
) {
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const latestProjects = document.getElementById("latest");
  //     const latestProjectsPosition = latestProjects.getBoundingClientRect().top;
  //     setLatestPosition(latestProjectsPosition);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [setLatestPosition]);
  const settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow classToUse={styles.nextClients} />,
    prevArrow: <PrewArrow classToUse={styles.prevClients} />,
    className: styles.clientsSlider,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div id="latest">
      <div className={styles.clientsAreSayingHeader}>
        <h2>Clients are saying</h2>
      </div>
      <div className={styles.backgroundContainer}>
        <Slider {...settings}>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>RANDY JACKSON</h3>
              <p>AMERICAN EXECUTIVE AND TELEVISION PRESENTER</p>
            </div>
            <p>
              I can manage different events, but i had an experience, when my
              partners were very busy with their things and couldn't help me,
              and i need to find qualified people who do their job properly. The
              main point for them was to provide high-quality sound within a bit
              extreme conditions.... If i am not mistaken, we required 6
              microphones and event's gonna be covered acoustically perfect! Web
              helped me much, and I got the contacts of “1987''. must say, i am
              glad they helped me. i even had many things recommended, other
              words, decisions they make are methods're of high usability.
              Thanks for being responsible, it proves that you must du what you
              do.
            </p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>JUSTIN CHOI</h3>
              <p>CEO</p>
            </div>
            <p>
              What to say... one of my bosses told me to make a wow laser show
              for the finest, first i’ve been dealing with one agency whose
              service's not mych xpensive. They took our money in advance and
              then stared on the wall,,, i was much shocked on that, hey, that
              was the only time, i risked actually to be fired, i'm hazy about
              another position in other company that i could find that for the
              same money that my bosses paid me. Friend in need is a friend
              indeed - genius was a man who said like that. One friend of mine
              saved my career and said that I should be in touch with Master
              1987. I had nothing more to do cause i missed all possible
              deadlines. - Though their service is not as cheap as chips, both
              satisfied with what we have both done. Good luck guys! wish you
              success, what costs little, is little esteemed., or is not
              esteemed at all.
            </p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>PAULA PARK</h3>
              <p>MARKETING AND IMPACT MANAGER</p>
            </div>
            <p>
              Our company first cooperated with Masters 1987 half a year ago. We
              had to find somebody able to provide perfect sound during the
              session, not only make it louder than a bomb, but the quality
              ought to meet the requirements set. Then was another problem we
              had faced. The event itself was an open-air. and one of our
              substantial tasks was to provide proportional distribution of
              sound.. Masters 1987 have shown their best skills but that is only
              half done.. They even suggested their approaches in things we need
              to implement. And till now i have contacted many different teams,
              but i had not found enough qualified experts, like Masters 1987
              are. Keep in touch!
            </p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>CASEY DOLKAS</h3>
              <p>VICE PRESIDENT OF BUSINESS DEVELOPMENT & CREATIVE PROJECTS</p>
            </div>
            <p>
              Once, our task was to organize an outstanding event, and we had to
              use not only sound and light effects, but also to deal with
              televiewers, projectors, and what's more important that we had to
              make like a whole laser-show! We had to spent much time looking
              for some team whose skills would be professional enough to fulfil
              our task. That was a bit complicated to do that, because last time
              we already had an occasion when so-called, “specialists” had let
              us down! We followed some advice to cooperate with Masters 1987, I
              have just answered primal questions, got through the briefing
              and... that was all I had to do! All other things to do was the
              part of work of "1987"-Team. Event went well enough! Thank you!
            </p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>MAX COLT</h3>
              <p>FOUNDER AT THE F-RENDER</p>
            </div>
            <p>
              i've been cooperating with Masters for years and have nothing
              negative to say. Satisfaction only. Anytime. Anywhere. We have
              been made large nmber of events, small ones and enough big
              arrangements. Their talent is godly! Masters are neat in
              everything they touch, if smth wrong happened, it was minimised
              and did not influenced anybody. I have not seen that anyone
              noticed smth... I think that it's the required experince, and they
              have it! I neglect any other offers on cooperation. As long as
              Masters do their best and they all go out, whatever happens, I
              will not let them down. Stay perfect!
            </p>
          </div>

          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>SCOTT</h3>
              <p>PRESIDENT AND EXECUTIVE PRODUCER</p>
            </div>
            <p>Hi team!</p>
            <p>
              Just wanted to extend a big THANK YOU for Saturday’s Comedy
              Central ROAST of Bruce Willis. Your team was amazing and
              everything went so well.
            </p>
            <p>
              We really appreciated your collaborative spirit and can do
              attitudes throughout the whole process. We especially appreciated
              everyone’s flexibility as we dealt with an ever-changing start
              time ;).
            </p>
            <br />
            <p>Looking forward to the next one!</p>
            <p>Cheers</p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>CHUCK</h3>
              <p>EVENT PRODUCER</p>
            </div>
            <p>
              The show went perfectly, even with some last minute changes we
              made. Every member of the crew was professional and helpful.
              Rowland was a great help with every request we made. My thanks for
              all your contributions to our success.
            </p>
            <br />
            <p>We look forward to working with you again in the future.</p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>DREW</h3>
              <p>CEO</p>
            </div>
            <p>
              Just wanted to take a moment to thank you for your hard work. Each
              of you helped us take the show to the next level. This proved to
              be a complex show with many competing and conflicting demands and
              you all handled it professionally and courteously. Each of your
              efforts to improvise and problem solve on the fly did not go
              unnoticed and I truly appreciate your commitment to the job.
            </p>
            <br />
            <p>
              So, CHEERS to a great show and I look forward to working with you
              in the future!
            </p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>EM</h3>
              <p>EVENT PRODUCER</p>
            </div>
            <p>Hi team!</p>
            <p>
              Just wanted to extend a big THANK YOU for Saturday’s Comedy
              Central ROAST of Bruce Willis. Your team was amazing and
              everything went so well.
            </p>
            <p>
              We really appreciated your collaborative spirit and can do
              attitudes throughout the whole process. We especially appreciated
              everyone’s flexibility as we dealt with an ever-changing start
              time ;).
            </p>
            <br />
            <p>Looking forward to the next one!</p>
            <p>Cheers</p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>DANIELLE</h3>
              <p>EVENT PRODUCER</p>
            </div>
            <p>
              Hi! Last night the Academy Museum looked incredible! Thank you for
              the attention to details that went into making the event a true
              success!
            </p>
            <br />
            <p>Please relay our thanks to the entire team!</p>
          </div>
          <div className={styles.slideContainer}>
            <div className={styles.headerInSlider}>
              <h3>DEREK</h3>
              <p>SPECIAL EVENTS MANAGER</p>
            </div>
            <p>
              Thank you so much for all of your help making our event last night
              a success. Hope to work with you all again soon!
            </p>
          </div>
        </Slider>
        <div className={styles.arrowsContainer}></div>
        <div className={styles.desktopText}>
          <h2>
            We understand that its the most important event for you
            <span>.</span>
            That's why we treat your event as our own<span>.</span>
          </h2>
        </div>
      </div>
    </div>
  );
}
